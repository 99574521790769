// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-examples-js": () => import("./../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-digest-index-js": () => import("./../src/pages/digest/index.js" /* webpackChunkName: "component---src-pages-digest-index-js" */),
  "component---src-pages-industry-index-js": () => import("./../src/pages/industry/index.js" /* webpackChunkName: "component---src-pages-industry-index-js" */),
  "component---src-pages-news-index-js": () => import("./../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-platform-index-js": () => import("./../src/pages/platform/index.js" /* webpackChunkName: "component---src-pages-platform-index-js" */),
  "component---src-pages-processors-index-js": () => import("./../src/pages/processors/index.js" /* webpackChunkName: "component---src-pages-processors-index-js" */),
  "component---src-pages-solutions-index-js": () => import("./../src/pages/solutions/index.js" /* webpackChunkName: "component---src-pages-solutions-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-developers-page-js": () => import("./../src/templates/developers-page.js" /* webpackChunkName: "component---src-templates-developers-page-js" */),
  "component---src-templates-digest-article-js": () => import("./../src/templates/digest-article.js" /* webpackChunkName: "component---src-templates-digest-article-js" */),
  "component---src-templates-faq-page-js": () => import("./../src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-get-started-page-js": () => import("./../src/templates/get-started-page.js" /* webpackChunkName: "component---src-templates-get-started-page-js" */),
  "component---src-templates-how-it-works-page-js": () => import("./../src/templates/how-it-works-page.js" /* webpackChunkName: "component---src-templates-how-it-works-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-industry-page-js": () => import("./../src/templates/industry-page.js" /* webpackChunkName: "component---src-templates-industry-page-js" */),
  "component---src-templates-mission-page-js": () => import("./../src/templates/mission-page.js" /* webpackChunkName: "component---src-templates-mission-page-js" */),
  "component---src-templates-news-article-js": () => import("./../src/templates/news-article.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-templates-platform-page-js": () => import("./../src/templates/platform-page.js" /* webpackChunkName: "component---src-templates-platform-page-js" */),
  "component---src-templates-pricing-page-js": () => import("./../src/templates/pricing-page.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */),
  "component---src-templates-privacy-page-js": () => import("./../src/templates/privacy-page.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-processor-page-js": () => import("./../src/templates/processor-page.js" /* webpackChunkName: "component---src-templates-processor-page-js" */),
  "component---src-templates-solutions-page-js": () => import("./../src/templates/solutions-page.js" /* webpackChunkName: "component---src-templates-solutions-page-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-terms-page-js": () => import("./../src/templates/terms-page.js" /* webpackChunkName: "component---src-templates-terms-page-js" */),
  "component---src-templates-why-page-js": () => import("./../src/templates/why-page.js" /* webpackChunkName: "component---src-templates-why-page-js" */)
}

